.primary-button {
  color: var(--sixty);
  background-color: var(--thirty);
  border-radius: 2px;
}

.primary-button svg {
  color: var(--sixty);
}

.width-100 {
  width: 100%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.margin-top-1 {
  margin-top: 1rem;
}

.margin-bottom-1 {
  margin-bottom: 1rem;
}

.padding-1p5 {
  padding: 1.5rem;
}

.example-card {
  display: flex;
  align-items: flex-start;
}

.example-card > div:first-of-type {
  flex-grow: 1;
}

.example-card .img-wrapper {
  min-width: 5rem;
  max-width: 5rem;
  min-height: 5rem;
  max-height: 5rem;
  overflow: hidden;
  display: flex;
  place-items: center;
  position: relative;
}

.example-card img,
.example-card svg {
  max-width: 5rem;
  max-height: 5rem;
  margin: auto;
}

.example-card-text {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.float-right {
  float: right;
}

.wired-rendered {
  opacity: 1;
}

.stripped-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.checkbox-li {
  display: flex;
  align-items: stretch;
}

.checkbox-li > .wired-rendered:first-child {
  padding-top: 2rem;
}

.center-y {
  display: flex;
  align-items: center;
}

.center-x {
  text-align: center;
}

.flex-center-x {
  display: flex;
  justify-content: center;
}

.no-underline {
  text-decoration: none;
}

.pointer {
  cursor: pointer;
}

.anchor {
  text-decoration: underline;
  cursor: pointer;
}

.background-60 {
  background-color: var(--sixty);
}

.dialog {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0.5rem;
}

.max-width-med {
  max-width: 500px;
}

.dialog > * {
  align-self: flex-end;
  margin-right: 1rem;
}

.sans-serif {
  font-family: sans-serif;
}

.button-spinner {
  height: 1rem;
  top: -1.25rem;
  transform: scale(0.5);
}
